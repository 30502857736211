<template>
  <div v-if="item" class="stocks-products stocks-products-full">
    <div class="stocks-products__slide-discount">
      <img
        class="stocks-products__slide-mob"
        :src="
          $helpers.getAbsolutePath(
            item.attributes.backgound_mob.data.attributes.url
          )
        "
        alt="slide image"
      />
      <img
        class="stocks-products__slide-desk"
        :src="
          $helpers.getAbsolutePath(
            item.attributes.backgound_desktop.data.attributes.url
          )
        "
        alt="slide image"
      />
      <div class="container">
        <div class="stocks-products-full__slide-content">
          <h1
            :style="getTitleColor(item)"
            class="stocks-products-full__slide-title-discount"
          >
            {{ item.attributes.label }}
          </h1>
          <p
            :style="getTitleColor(item)"
            class="stocks-products-full__slide-text-discount"
          >
            {{ item.attributes.slogan }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="currentStock" class="stocks-products-full__card">
      <div class="container">
        <div class="stocks-products-full__header">
          <div class="stocks-products-full__top">
            <h1 class="stocks-products-full__title title">
              {{ $t("termsAction") }}
            </h1>
          </div>
          <span class="stocks-products-full__date">
            {{ $t("validity") }}
            {{ getDatePeriod(item) }}
          </span>
          <span v-if="currentStock.isRaffle" class="stocks-products-full__draw">
            {{ $t("draw") }}
          </span>
        </div>
        <div class="stocks-products-full__card-content">
          <div
            v-if="item.attributes.conditions"
            class="stocks-products-full__list"
          >
            <vue-markdown
              class="stocks-products-full__number-list"
              :source="item.attributes.conditions"
              style="white-space: pre-line"
              :anchorAttributes="{ target: '_blank' }"
            >
              {{ item.attributes.conditions }}
            </vue-markdown>

            <a
              v-if="item.attributes.link_to_rules"
              :href="item.attributes.link_to_rules"
              class="stocks-products-full__rules"
              target="_blank"
              >{{ $t("fullPromotion") }}</a
            >
          </div>
          <div v-if="currentStock" class="stocks-products-full__img">
            <p>
              {{ currentStock.isClub ? $t("membersOnly") : $t("forBuyers") }}
            </p>

            <img
              src="@/assets/img/icons/logo.svg"
              alt="stock image"
              width="315"
              height="356"
              class="stocks-products-full__img-desktop"
            />
            <!-- <img
              :src="getImage(currentStock.image)"
              alt="stock image"
              width="315"
              height="356"
              class="stocks-products-full__img-desktop"
          > -->
            <!-- <div v-else class="stock__basket">
              <img src="@/assets/img/stocks/basket.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="products && products.length"
      class="stocks-products-participating"
    >
      <div class="container">
        <h2 class="stocks-products-participating__title title">
          {{ $t("productsPromotion") }}
        </h2>

        <div class="stocks-products-participating__items">
          <product-card v-for="item in products" :key="item.id" :item="item" />
        </div>

        <button
          v-if="!stocksPagination.isLastPage"
          class="stocks-products-participating__button"
          @click="seeAll"
        >
          {{ $t("seeAll") }}
        </button>
      </div>
    </div>

    <div v-if="stocks.length" class="stocks-products__content">
      <div class="container">
        <div class="stocks-products__suggestions">
          <div class="stocks-products__top header-block">
            <h2 class="title">
              {{ $t("interestingOffers") }}
            </h2>
            <locale-router-link to="stocks" class="header-block__link">{{
              $t("showMore")
            }}</locale-router-link>
          </div>

          <stocks-list v-if="stocks" :items="stocks" />
        </div>
      </div>
      <!-- container -->
    </div>
    <!-- 
    <shops-map
      v-if="shopsList"
      :shops-list="shopsList"
      :is-show-shop-types="true"
    /> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueMarkdown from "vue-markdown";
import { getPromos, getPromo } from "@/api";
import { getGooglePlay, getAppStore, getAppGallery } from "@/api/links";
import { getProductsByFilters } from "@/api/products";
export default {
  name: "FullSpecial",
  components: {
    VueMarkdown,
    ProductCard: () => import("@/components/products/ProductCard.vue"),
    StocksList: () => import("@/components/stocks/StocksList.vue"),
    // ShopsMap: () => import("@/components/maps/ShopsMap.vue"),
  },
  data() {
    return {
      item: null,
      products: null,
      stocks: null,
      stocksPagination: { page: 1, pageSize: 1500, isLastPage: false },
      currentStock: null,
      googlePlayLink: null,
      appStoreLink: null,
      appGalleryLink: null,
      stocksTypes: {
        action_loyalty: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: false,
        },
        action_all: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: false,
        },
        raffle_loyalty: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: true,
        },
        raffle_all: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: true,
        },
        action_loyalty_discount: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: false,
        },
        action_all_discount: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: false,
        },
        raffle_loyalty_discount: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: true,
        },
        raffle_all_discount: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: true,
        },
      },
    };
  },
  mounted() {
    console.log(this.getTitleColor(this.item));
  },
  created() {
    getPromos(
      { type: "current" },
      { page: 1, pageSize: 3 },
      this.$route.params.id
    ).then((stocks) => {
      this.stocks = stocks.data;
    });

    getPromo(this.$route.params.id).then((stock) => {
      this.item = stock.data;

      if (this.item.attributes.promo_type === "only_discount") {
        this.$router.push("/stocks/products/" + this.item.id);
        return;
      }
      this.currentStock = this.stocksTypes[this.item.attributes.promo_type];
      if (this.item.attributes?.discount?.data?.id) {
        getProductsByFilters(
          {
            categories: [],
            discountTypes: [this.item?.attributes?.discount?.data?.id],
            shopTypes: [],
          },
          { field: "discount", sortType: "desc" },
          {
            page: this.stocksPagination.page,
            pageSize: this.stocksPagination.pageSize,
          },
          this.selectedCity?.id
        ).then((response) => {
          this.products = response.data;
          if (this.stocksPagination.page === response.meta.pagination.page) {
            this.stocksPagination.isLastPage = true;
          }
        });
      }
    });
    getGooglePlay().then((googlePlay) => {
      this.googlePlayLink = googlePlay.data.attributes.link;
    });
    getAppStore().then((appStore) => {
      this.appStoreLink = appStore.data.attributes.link;
    });
    getAppGallery().then((appGallery) => {
      this.appGalleryLink = appGallery.data.attributes.link;
    });
  },

  computed: {
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler() {
        // getPromo(id).then((stock) => {
        //   this.item = stock.data;
        // });
        this.getFullStock();
      },
    },
    selectedCity: {
      handler(selectedCity) {
        this.getFullStock(selectedCity.id);
      },
    },
  },

  methods: {
    seeAll() {
      this.stocksPagination.page++;
      this.getFullStock();
    },
    getFullStock(cityId) {
      getPromo(this.$route.params.id).then((stock) => {
        this.item = stock.data;
        if (this.item.attributes.promo_type === "only_discount") {
          this.$router.push("/stocks/products/" + this.item.id);
          return;
        }
        this.currentStock = this.stocksTypes[this.item.attributes.promo_type];
        if (this.item.attributes?.discount?.data?.id) {
          getProductsByFilters(
            {
              categories: [],
              discountTypes: [this.item?.attributes?.discount?.data?.id],
              shopTypes: [],
              cityId,
            },
            { field: "discount", sortType: "desc" },
            {
              page: this.stocksPagination.page,
              pageSize: this.stocksPagination.pageSize,
            },
            this.selectedCity?.id
          ).then((response) => {
            this.products = response.data;
            if (this.stocksPagination.page === response.meta.pagination.page) {
              this.stocksPagination.isLastPage = true;
            }
          });
        }
      });
      getPromos(
        { type: "current" },
        { page: 1, pageSize: 3 },
        this.$route.params.id
      ).then((stocks) => {
        this.stocks = stocks.data;
      });
    },
    getBigImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.backgound_desktop?.data?.attributes?.url
      );
    },
    getSmallImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.backgound_mob?.data?.attributes?.url
      );
    },
    getDateStart(item) {
      const dateFromStr = new Date(item.attributes?.date_start);
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },
    getDateEnd(item) {
      const dateFromStr = new Date(item.attributes?.date_end);
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },

    getImage(url) {
      return require("@/assets/img" + url);
    },

    getDatePeriod(item) {
      if (Number(this.getDateStart(item)) === Number(this.getDateEnd(item))) {
        return this.getDateStart(item);
      }
      return this.getDateStart(item) + " - " + this.getDateEnd(item);
    },

    getTitleColor(item) {
      return "color:" + item?.attributes?.bg_color;
    },
  },
};
</script>

<style lang="scss">
.app-info {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  min-width: 300px;
  width: 300px;
  position: relative;
  &-qr {
    width: 150px;
    height: 150px;
    @media screen and (max-width: 640px) {
      display: none;
    }
    &-decore {
      position: absolute;
      top: -6px;
      right: -8px;
    }
  }
}

.app-list-link {
  width: 135px;
  height: 39px;
  object-fit: cover;
}

.magnum-club__btn {
  display: block;

  & + & {
    margin-top: 15px;
  }
}

.stocks-products__slide-mob,
.stocks-products__slide-desk {
  height: 100%;
  width: 100%;
}
.stocks-products__slide-mob {
  display: none;
}

@media (max-width: 1030px) {
  .app-info-qr-decore {
    // right: -35px;
  }
}

@media (max-width: 770px) {
  .app-info-qr-decore {
    // right: 50%;
    // transform: translateX(165px);
  }
}

@media (max-width: 700px) {
  .stocks-products-full__slide-content {
    top: 10px;
  }
  .stocks-products-full__slide-title-discount {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .stocks-products-full__slide-text-discount {
    font-size: 18px;
  }
}

@media (max-width: 640px) {
  .app-info-qr-decore {
    display: none;
  }
}

@media (max-width: 550px) {
  .stocks-products__slide-mob {
    display: block;
  }
  .stocks-products__slide-desk {
    display: none;
  }
  .stocks-products-full__slide-content {
    top: 50px;
  }
  .stocks-products-full__slide-title-discount {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .stocks-products-full__slide-text-discount {
    font-size: 25px;
  }
}

@media (max-width: 400px) {
  .stocks-products-full__slide-content {
    top: 50px;
  }
  .stocks-products-full__slide-title-discount {
    margin-bottom: 10px;
    font-size: 25px;
  }
  .stocks-products-full__slide-text-discount {
    font-size: 18px;
  }
}
</style>
